/* Base */

.button {
  --button-bg: transparent;
  --button-p: 0.825em;

  /* This is for the animated side shift effect, which is close to instant in the design */
  --button-animated-transition-duration: 0.1s;
  --button-animated-direction: 1;
  --button-animated-offset: calc(var(--button-animated-direction) * 3em);

  padding: var(--button-p);

  border-radius: 9999px;
  background-color: var(--button-bg);
  outline-offset: theme("spacing.3xs");
  font-size: theme("fontSize.default-md");
  outline-offset: theme("spacing.3xs");

  transition:
    background-color var(--button-transition-duration) ease,
    color var(--button-transition-duration) ease,
    border-color var(--button-transition-duration) ease;
}


/* Primary */
.primary {
  --button-bg: theme("colors.primaryButton.bg");

  color: theme("colors.primaryButton.text");
  position: relative;
}

.primary:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
}

.primary:hover:after {
  background-color: theme("colors.primaryButton.hover");
}

.primary:focus:after {
  background-color: theme("colors.primaryButton.focus");
}

.primary:active:after {
  background-color: theme("colors.primaryButton.pressed");
}

/* Secondary */
.secondary {
  color: theme("colors.secondaryButton.bg");
  border: 1px solid currentColor;
}

.secondary:hover,
.secondary:focus,
.secondary:active {
  /* Adjusting the padding to avoid height/width shifts. */
  --button-p: calc(0.825em - 0.8px);

  border-width: 2px;
}

.secondary:hover {
  color: theme("colors.secondaryButton.hover");
}

.secondary:focus {
  color: theme("colors.secondaryButton.focus");
}

.secondary:active {
  --button-bg: theme("colors.shades.black.200");

  color: theme("colors.secondaryButton.pressed");
}

/* Disabled */
.button:disabled {
  cursor: not-allowed;
  background-color: theme('colors.button.disable.DEFAULT'); 
  border-color: transparent;
  color: theme('colors.button.disable.text');
}

/*
  Inner: wrapper element around icon

  Needed to give the effect of switching the icon inside a "small viewport" inside the padding,
  instead of it switching when it goes outside the rounded border of the button.
*/
.inner {
  display: grid;
  grid: [content] 1fr / [content] 1fr;
  place-items: center;
  overflow: hidden;
}

.icon {
  grid-area: content;
}

/* Animated duration */
.animated .icon {
  transition: transform var(--button-animated-transition-duration) ease-out;
}

/* Right / Default */
.animated .pre-arrow {
  /* Sets up an overlap of exactly the size and gap of the "pre" arrow */
  margin-left: calc(-1 * var(--button-animated-offset));
}


.animated:hover .icon,
.animated:focus .icon {
  transform: translateX(calc(var(--button-animated-offset) / 2));
}

/* Left */
.animated.left {
  --button-animated-direction: -1;
}
.animated.left .post-arrow {
  /* Sets up an overlap of exactly the size and gap of the "pre" arrow */
  margin-right: calc(var(--button-animated-offset));
}
.animated.left .pre-arrow {
  margin-left: 0;
}

/* Up */
.animated.up {
  --button-animated-direction: -1;
}
.animated.up .post-arrow {
  transform: translateY(calc(-1 * (var(--button-animated-offset) / 2)));
}
.animated.up .pre-arrow {
  margin-left: 0;
}
.animated.up:hover .pre-arrow,
.animated.up:focus .pre-arrow {
  transform: translateY(calc((var(--button-animated-offset) / 2)));
}
.animated.up:hover .post-arrow,
.animated.up:focus .post-arrow {
  transform: translateY(0);
}

/* Down */
.animated.down .post-arrow {
  transform: translateY(calc(-1 * (var(--button-animated-offset) / 2)));
}
.animated.down .pre-arrow {
  margin-left: 0;
}
.animated.down:hover .pre-arrow,
.animated.down:focus .pre-arrow {
  transform: translateY(calc((var(--button-animated-offset) / 2)));
}
.animated.down:hover .post-arrow,
.animated.down:focus .post-arrow {
  transform: translate(0);
}




