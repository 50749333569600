/* Base */

.button {
  --button-bg: transparent;
  --button-shade: transparent;
  --button-transition-duration: 0.25s;
  /* Base/non-base needed for adjustments on hover */
  --button-px-base: theme("spacing.md");
  --button-py-base: theme("spacing.xs");
  --button-px: var(--button-px-base);
  --button-py: var(--button-py-base);
  --button-arrow-size: theme("fontSize.default-md");
  --button-gap: theme("spacing.2xs");
  --button-editorial-offset: var(--button-arrow-size);

  /* This is for the editorial side shift effect, which is close to instant in the design */
  --button-editorial-transition-duration: 0.1s;

  font-size: theme("fontSize.strong-sm");
  font-weight: 700;
  font-family: theme("fontFamily.sans");

  border-radius: 9999px;
  line-height: 1.2;
  background-color: var(--button-bg);
  outline-offset: theme("spacing.3xs");
  overflow: hidden;

  transition:
    background-color var(--button-transition-duration) ease,
    color var(--button-transition-duration) ease,
    border-color var(--button-transition-duration) ease;
}

/* Inner container with the purpose of adding "shade" background when hover/focus/active */
.inner {
  display: flex;
  gap: var(--button-gap);
  place-items: center;
  justify-content: center;

  /* Putting the padding on this element and not root, ensures the shade covers the entire button */
  padding: var(--button-py) var(--button-px);

  background-color: var(--button-shade);

  transition: background-color var(--button-transition-duration) ease;
}

.text {
  display: inline-flex;
  place-items: center;
  gap: theme("spacing.2xs");
  min-height: calc(theme("lineHeight.snug") * 1em);
}

.small {
  --button-px-base: theme("spacing.sm");
  --button-py-base: theme("spacing.2xs");
}

/* Primary (default/solid) */
.primary {
  --button-bg: theme("colors.primaryButton.bg");

  color: theme("colors.primaryButton.text");
}

.primary:hover {
  --button-shade: theme("colors.primaryButton.hover");
}

.primary:focus {
  --button-shade: theme("colors.primaryButton.focus");
}

.primary:active {
  --button-shade: theme("colors.primaryButton.pressed");
}

/* Secondary (outline) */
.secondary {
  color: theme("colors.secondaryButton.bg");
  border: 1px solid currentColor;
}

.secondary:hover,
.secondary:focus,
.secondary:active {
  /* Adjusting the padding to avoid height/width shifts. */
  --button-py: calc(var(--button-py-base) - 1px);
  --button-px: calc(var(--button-px-base) - 1px);

  border-width: 2px;
}

.secondary:hover {
  color: theme("colors.secondaryButton.hover");
}

.secondary:focus {
  color: theme("colors.secondaryButton.focus");
}

.secondary:active {
  --button-shade: theme("colors.shades.black.200");

  color: theme("colors.secondaryButton.pressed");
}

/* Ternary (tex/link-like) */
.ternary {
  color: theme("colors.secondaryButton.bg");
  text-decoration: underline;
}

.ternary:hover {
  color: theme("colors.secondaryButton.hover");
}

.ternary:focus {
  color: theme("colors.secondaryButton.focus");
}

.ternary:active {
  color: theme("colors.secondaryButton.pressed");
}

/* Disabled */
.primary:disabled,
.secondary:disabled,
.ternary:disabled {
  cursor: not-allowed;
  background-color: theme("colors.button.disable.DEFAULT");
  color: theme("colors.button.disable.text");
}

/* Pre/post Arrows */
.pre-arrow,
.post-arrow {
  font-size: theme("fontSize.default-md");
  color: inherit;

  transition: color var(--button-transition-duration) ease;
}

.secondary .pre-arrow,
.secondary .post-arrow {
  color: currentColor;
}

/* Editorial */
.editorial {
  --button-px-base: theme("spacing.sm");
}

.editorial.small {
  --button-px-base: theme("spacing.xs");
}

.editorial .text {
  /* Sets up an overlap of exactly the size and gap of the "pre" arrow */
  margin-left: calc(-1 * (var(--button-editorial-offset)));

  transition: transform var(--button-editorial-transition-duration) ease-out;
}

/* You can use custom italic words inside the editorial button */
.editorial .text em,
.editorial .text i {
  font-family: theme("fontFamily.serifRegular");
  /* Custom size to align it a bit with sans */
  font-size: 1.125em;
  font-weight: 500;
}

/* Animation durations */
.editorial .pre-arrow,
.editorial .post-arrow {
  transition: opacity, transform, var(--button-editorial-transition-duration) ease-out;
}

/* Editorial animations */
.editorial:is(:hover, :focus, :active) .text {
  transform: translateX(var(--button-editorial-offset));
}

/* Stating pos */
.editorial .pre-arrow {
  opacity: 0;
  transform: translateX(calc(-1 * var(--button-editorial-offset)));
}

.editorial .post-arrow {
  opacity: 1;
  transform: translateX(0);
}

/* Ending pos */
.editorial:is(:hover, :focus, :active) .pre-arrow {
  opacity: 1;
  transform: translateX(0);
}

.editorial:is(:hover, :focus, :active) .post-arrow {
  opacity: 0;
  transform: translateX(var(--button-editorial-offset));
}
