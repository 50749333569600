@import "./theme.css";

.hs-form-private {
  display: grid;
  gap: theme("spacing.sm");
}

.hs-form-private fieldset {
  max-width: 100% !important;

  display: flex;
  gap: theme("spacing.md");
}

.hs-form-private fieldset:has(input[type=hidden]) {
  display: none;
}

.hs-richtext {
  display: none !important;
}

.hs-form-field {
  display: grid;
  align-items: center;
  grid-auto-rows: max-content;

  width: 100%;
  padding-top: theme("spacing.sm");

  font-size: theme("fontSize.default-sm");
  line-height: theme("lineHeight.snug");
  color: theme("colors.secondaryUI.bg");
  background: none;
}

.hs-form-field:focus-within {
  color: theme("colors.secondaryUI.focus");
}

.hs-form-field:hover {
  color: theme("colors.secondaryUI.hover");
}

.hs-form-field .input,
.hs-form-field > label {
  grid-column: -1/1;
  grid-row: -1/1;
}

.hs-form-field .input {
  margin-right: 0 !important;
  width: 100%;
  padding-bottom: calc(theme("spacing.2xs") - 2px);
  border-bottom: 1px solid currentColor;
}

.hs-form-field input {
  width: 100% !important;
  outline: none !important;
  background: none;
}

.hs-form-field > label {
  margin-bottom: calc(theme("spacing.2xs") - 2px);
  transform-origin: center left;
  transition: transform .25s ease;
}

.hs-form-field:focus-within > label,
.hs-form-field:not(:has(input:placeholder-shown)) > label {
  transform: translateY(-100%) scale(0.75);
}

.hs-form-booleancheckbox-display {
  display: grid;
  grid-template-columns: max-content 1fr;

  line-height: theme("lineHeight.relaxed");
}

.hs-error-msgs {
  display: flex;
  flex-direction: column;
  gap: theme("spacing.sm");
  margin-top: theme("spacing.2xs");
  font-size: theme("fontSize.default-sm");
  color: theme("colors.system/error");
}

.hs-submit {
  margin-top: theme("spacing.md");
}

.hs-submit .actions {
  display: flex;
  place-content: flex-end;
}

.hs-button {
  padding: theme("spacing.xs") theme("spacing.md");
  position: relative;
  overflow: hidden;

  font-size: theme("fontSize.strong-sm");
  font-weight: 700;
  color: theme("colors.primaryButton.text");
  background-color: theme("colors.primaryButton.bg");
  border-radius: theme("borderRadius.full");

  cursor: pointer;
}
